import type { ClientPaymentMethodResponseDto, PaymentMethod } from 'dtos';
import type { UseFormikHelpers } from 'utils/client/types';
import type { ClientInvoiceFormValues } from './schema';
import { FieldNames } from './schema';

type GetClientInvoiceFormEventHandlers = {
  values: ClientInvoiceFormValues;
  setFieldValue: UseFormikHelpers<ClientInvoiceFormValues>['setFieldValue'];
  paymentMethods: ClientPaymentMethodResponseDto[];
};

export const getClientInvoiceFormEventHandlers = ({
  values: { amount: totalValue, processingCostPct: processingCostPctValue },
  setFieldValue,
  paymentMethods,
}: GetClientInvoiceFormEventHandlers) => ({
  handleProcessingCostsChange: ({
    field,
    value,
  }: {
    field: 'total' | 'costAmount' | 'costPct' | 'netTotal';
    value: number;
  }) => {
    switch (field) {
      case 'total': {
        const netTotal = (value * (100 - (processingCostPctValue || 0))) / 100;
        const processingCost = value - netTotal;
        setFieldValue(FieldNames.NET_TOTAL, netTotal);
        setFieldValue(FieldNames.PROCESSING_COST, processingCost);
        setFieldValue(FieldNames.AMOUNT, value);
        break;
      }
      case 'costAmount': {
        const pct = totalValue ? (value / totalValue) * 100 : 0;
        const netTotal = totalValue ? totalValue - value : 0;

        setFieldValue(FieldNames.PROCESSING_COST, value);
        setFieldValue(FieldNames.PROCESSING_COST_PCT, pct);
        setFieldValue(FieldNames.NET_TOTAL, netTotal);

        break;
      }
      case 'costPct': {
        const amount = totalValue ? (value / 100) * totalValue : 0;
        const netTotal = totalValue ? totalValue - amount : 0;

        setFieldValue(FieldNames.PROCESSING_COST, amount);
        setFieldValue(FieldNames.PROCESSING_COST_PCT, value);
        setFieldValue(FieldNames.NET_TOTAL, netTotal);

        break;
      }
      case 'netTotal': {
        const netValue = value;

        const total = netValue / (1 - (processingCostPctValue || 0) / 100);

        const processingCosts = total ? total - netValue : 0;

        setFieldValue(FieldNames.AMOUNT, total);

        setFieldValue(FieldNames.PROCESSING_COST, processingCosts);
        setFieldValue(FieldNames.NET_TOTAL, netValue);

        break;
      }
    }
  },

  handleClientPaymentMethodChange: (value: string) => {
    const paymentMethod = paymentMethods.find((x) => x.id === value);
    const processingCostPct = paymentMethod?.processingCostPct || 0;

    setFieldValue(FieldNames.PROCESSING_COST_PCT, processingCostPct);

    const netAmount = (totalValue || 0) * (1 - processingCostPct / 100);

    const processingCosts = totalValue ? totalValue - netAmount : 0;
    setFieldValue(FieldNames.NET_TOTAL, netAmount);
    setFieldValue(FieldNames.PROCESSING_COST, processingCosts);

    setFieldValue(
      FieldNames.CLIENT_PAYMENT_METHOD,
      paymentMethod?.type as PaymentMethod,
    );
    setFieldValue(FieldNames.CLIENT_PAYMENT_METHOD_ID, value);

    if (paymentMethod?.supportsDirectPayment) {
      setFieldValue(FieldNames.PAID, false);
    }
  },
});
