import { AdvisorSelector, PercentTextField } from '@customFormik';
import { Stack } from '@mui/material';
import { FieldNames as fields } from './schema';

type ClientInvoiceSplitFormProps = {
  excludeAgencyUserIds?: string[];
};

export default function ClientInvoiceSplitForm({
  excludeAgencyUserIds = [],
}: ClientInvoiceSplitFormProps) {
  return (
    <Stack spacing={2}>
      <AdvisorSelector
        name={fields.ADVISOR}
        label="Advisor"
        includeAgencyName
        excludeAgencyUserIds={excludeAgencyUserIds}
      />
      <PercentTextField
        name={fields.SPLIT_PCT}
        label="Percentage"
        NumericFormatProps={{
          decimalScale: 2,
        }}
      />
    </Stack>
  );
}
