import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import T from 'components/common/T';
import UserInfoContext from 'context/user-info.context';
import type { ClientInvoiceResponseDto } from 'dtos';
import { enqueueSnackbar as toast } from 'notistack';
import { useContext } from 'react';
import { deleteClientInvoice } from 'requests/clientInvoices';

interface ClientInvoiceDeleteDialogProps {
  open: boolean;
  clientInvoice: ClientInvoiceResponseDto;
  onClose: () => void;
  onSuccess?: () => void;
}

const ClientInvoiceDeleteDialog = ({
  open,
  clientInvoice,
  onClose,
  onSuccess = () => null,
}: ClientInvoiceDeleteDialogProps) => {
  const userInfo = useContext(UserInfoContext);

  const canDelete = !clientInvoice.locked || userInfo?.isOrgUser;

  const handleConfirm = async () => {
    try {
      await deleteClientInvoice(clientInvoice.id);
      toast('Client payment deleted');
      onSuccess();
    } catch (e) {
      onClose();
    }
  };

  return canDelete ? (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Delete Client Payment</DialogTitle>
      <DialogContent>
        <T>Are you sure you want to delete this client payment?</T>
        {clientInvoice.locked && (
          <Alert severity="warning" sx={{ mt: 1 }}>
            <AlertTitle>Warning</AlertTitle>
            Changes will impact accounting.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleConfirm}>
          Delete Payment
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Payment Locked</DialogTitle>
      <DialogContent>
        <T>Please contact your administrator for assistance.</T>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="text" onClick={onClose}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientInvoiceDeleteDialog;
