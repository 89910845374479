import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Alert,
  Stack,
  type StackProps,
  type SxProps,
  type Theme,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';

const AccountingSyncIndicators = ({
  isLegacyPayment,
  isOrgUser,
  sx,
  ...props
}: {
  isLegacyPayment: boolean | undefined;
  isOrgUser: boolean | undefined;
  sx?: SxProps<Theme>;
} & StackProps) => {
  const indicators = [];
  const hasLegacyIndicator = isLegacyPayment && isOrgUser;
  if (hasLegacyIndicator) {
    indicators.push(<LegacyAlert />);
  }

  return indicators.length ? (
    <Stack sx={sx} {...props}>
      {indicators.map((indicator) => indicator)}
    </Stack>
  ) : null;
};

const LegacyAlert = () => (
  <Alert
    icon={<InfoOutlinedIcon color="secondary" />}
    sx={{
      backgroundColor: grey[900],
      color: 'secondary.main',
      borderRadius: 0,
    }}
  >
    <Typography>Legacy Data - Not synced to accounting</Typography>
  </Alert>
);

export default AccountingSyncIndicators;
