import { forwardRef, useEffect, useState } from 'react';
import {
  type InputAttributes,
  type NumberFormatBaseProps,
  NumericFormat,
} from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { id: string; value: number } }) => void;
  id: string;
}

const MoneyFormat = forwardRef<
  NumberFormatBaseProps<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        //there's a bug when combining fixedDecimalScale with formik:
        //if you start typing a number with a decimal, it will add a 0 in front of it then bounce the cursor to the start of the input
        //so we need to manually fix that here by catching the keydown event and manually moving the cursor
        if (
          event.key === '.' &&
          (!target.value || ['', '0.00', '.00'].includes(target.value))
        ) {
          target.value = '0.00';
          target.setSelectionRange(2, 2);
        }
        //else if backspace pressed and value is 0.00 and cursor is at the start of the input, then clear the input
        //sometimes with react number format we end up in this situation and we need to manually fix it
        else if (
          event.key === 'Backspace' &&
          target.value === '0.00' &&
          target.selectionStart === 0
        ) {
          window.setTimeout(() => {
            target.value = '';
          }, 0);
        }
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            value: values.floatValue || 0,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
});

export default MoneyFormat;
