import { Box, type BoxProps } from '@mui/material';
import T from 'components/common/T';

type ClientInvoiceCommissionSplitsAgencyAvatarProps = {
  initials: string;
  color?: string;
  sx?: BoxProps['sx'];
};

const ClientInvoiceCommissionSplitsAgencyAvatar = ({
  initials,
  color,
  sx,
}: ClientInvoiceCommissionSplitsAgencyAvatarProps) => (
  <Box
    sx={{
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      width: 40,
      minWidth: 40,
      borderRadius: 1,
      backgroundColor: color ?? '#eee',
      color: '#fff',
      ...sx,
    }}
  >
    <T>{initials ?? '--'}</T>
  </Box>
);

export default ClientInvoiceCommissionSplitsAgencyAvatar;
