import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
} from '@mui/material';
import T from 'components/common/T';
import PercentTextField from 'components/common/forms/PercentTextField';
import type { GroupedSplitsResponseDto, SplitResponseDto } from 'dtos';
import { useConfirm } from 'material-ui-confirm';
import { useState } from 'react';
import ClientInvoiceCommissionSplitsListItem from './ClientInvoiceCommissionSplitsListItem';

const ClientInvoiceCommissionSplitsList = ({
  commissionSplits,
  onSplitUpdate,
  onSplitRemove,
}: {
  commissionSplits: GroupedSplitsResponseDto | null;
  onSplitUpdate: (newSplit: SplitResponseDto) => void;
  onSplitRemove: (split: SplitResponseDto) => void;
}) => {
  const confirm = useConfirm();
  const [splitToEdit, setSplitToEdit] = useState<SplitResponseDto | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onClose = () => setSplitToEdit(null);

  const handleSplitUpdate = async () => {
    if (!splitToEdit) return;

    onSplitUpdate(splitToEdit);
    onClose();
  };

  const handleOnSplitChange = (pct: number) => {
    if (!splitToEdit || isNaN(pct)) return;

    pct < 0 || pct > 100
      ? setErrorMessage('Split percentage should be between 0 and 100')
      : setErrorMessage(null);

    setSplitToEdit({ ...splitToEdit, takePercent: pct });
  };

  const handleSplitRemove = (split: SplitResponseDto) => {
    confirm({
      title: 'Remove Split',
      description: 'Are you sure you want to remove this split?',
      confirmationButtonProps: {
        title: 'Remove',
        variant: 'contained',
        color: 'primary',
      },
      cancellationButtonProps: {
        title: 'Cancel',
        variant: 'text',
        color: 'primary',
      },
      dialogProps: {
        maxWidth: 'xs',
      },
    }).then(async () => {
      onSplitRemove(split);
      setSplitToEdit(null);
    });
  };

  const isPrimarySplit = !!commissionSplits?.primarySplits.find(
    (primarySplit) => primarySplit.agencyUserId === splitToEdit?.agencyUserId,
  );

  return (
    <>
      <Stack spacing={2}>
        <Box id="primary-split">
          {commissionSplits?.primarySplits.map((split, index) => (
            <ClientInvoiceCommissionSplitsListItem
              split={split}
              index={index}
              onClick={setSplitToEdit}
              key={split.id}
              showPrimaryAdvisorChip
            />
          ))}
        </Box>

        <Divider />

        {commissionSplits?.secondarySplits.map((secondarySplit) => (
          <>
            <Box key="secondary">
              {secondarySplit.map((split, index) => (
                <ClientInvoiceCommissionSplitsListItem
                  split={split}
                  index={index}
                  onClick={setSplitToEdit}
                  key={split.id}
                />
              ))}
            </Box>
            <Divider key="secondary-divider" />
          </>
        ))}
      </Stack>
      {!!splitToEdit && (
        <Dialog
          open={!!splitToEdit}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
          disableRestoreFocus
        >
          <DialogTitle>
            <Stack justifyContent="space-between" direction="row">
              <T variant="h6">Edit commission split</T>
              <Button
                variant="text"
                disabled={!splitToEdit?.canEdit || isPrimarySplit}
                onClick={() => handleSplitRemove(splitToEdit)}
              >
                Remove
              </Button>
            </Stack>
          </DialogTitle>

          <DialogContent>
            <PercentTextField
              autoFocus
              name="groupName"
              label="Split"
              variant="standard"
              fullWidth
              value={splitToEdit.takePercent}
              onChange={({ target }) => {
                handleOnSplitChange(Number(target.value));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              inputProps={{ inputMode: 'numeric' }}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={handleSplitUpdate}
              variant="contained"
              disabled={!!errorMessage}
            >
              Update Split
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ClientInvoiceCommissionSplitsList;
