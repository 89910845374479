import type { ListItem } from 'components/types';
import { type SplitResponseDto, SplitType } from 'dtos';
import type { FormikValues } from 'formik';
import { type ExtendedFormSchema, createYupSchema } from 'utils/client/types';
import * as yup from 'yup';

type AdvisorListItem = ListItem & {
  agencyUserId: string;
};

export enum FieldNames {
  ADVISOR = 'advisor',
  SPLIT_PCT = 'splitPct',
}

export interface ClientInvoiceSplitFormValues extends FormikValues {
  [FieldNames.ADVISOR]: AdvisorListItem | null;
  [FieldNames.SPLIT_PCT]: number | null;
}

const yupSchema: Record<`${FieldNames}`, yup.AnySchema> = {
  [FieldNames.ADVISOR]: yup.object().nullable().required('Advisor is required'),
  [FieldNames.SPLIT_PCT]: yup
    .number()
    .nullable()
    .required('Percentage is required')
    .min(0, 'Percentage must be greater than or equal to 0')
    .max(100, 'Percentage must be less than or equal to 100'),
};

const schema = createYupSchema<ClientInvoiceSplitFormValues>(yupSchema);

const getDefaultValues = (): ClientInvoiceSplitFormValues => {
  return {
    advisor: null,
    splitPct: null,
  };
};

const getEntityFromFormValues = (
  values: ClientInvoiceSplitFormValues,
): SplitResponseDto => {
  return {
    id: values[FieldNames.ADVISOR]?.id || '',
    agencyUserId: values[FieldNames.ADVISOR]?.agencyUserId || '',
    takePercent: values[FieldNames.SPLIT_PCT] || 0,
    type: SplitType.ADVISOR,
    name: values[FieldNames.ADVISOR]?.name || '',
    color: '#000000',
    canEdit: false,
    isOverride: false,
  };
};

const formSchema: ExtendedFormSchema<
  Partial<SplitResponseDto>,
  SplitResponseDto,
  ClientInvoiceSplitFormValues,
  typeof schema
> = {
  schema,
  getDefaultValues,
  getEntityFromFormValues,
};

export default formSchema;
