import type { TaxCodesResponseDto } from 'pages/api/tax-codes';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseTaxCodesOptions = {
  effectiveDate: Date;
};

export default function useTaxCodes({ effectiveDate }: UseTaxCodesOptions) {
  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<TaxCodesResponseDto, Error>(
    `tax-codes?effectiveDate=${effectiveDate.toISOString()}`,
    fetcher,
  );

  const { data: taxCodes = [] } = (data as TaxCodesResponseDto) || {};

  return { taxCodes, isLoading, error, refresh };
}
