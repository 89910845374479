import {
  ClientPaymentPaymentMethodSelector,
  CurrencySelector,
  FeeTypeSelector,
  PercentTextField,
} from '@customFormik';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  TextField as MuiTextField,
  Stack,
} from '@mui/material';
import TabPanel from 'components/common/TabPanel';
import MoneyFormat from 'components/common/forms/MoneyFormat';
import Checkbox from 'components/common/forms/formik/Checkbox';
import ClientSelector from 'components/common/forms/formik/ClientSelector';
import DatePicker from 'components/common/forms/formik/DatePicker';
import MoneyTextField from 'components/common/forms/formik/MoneyTextField';
import Select from 'components/common/forms/formik/Select';
import TaxCodeSelector from 'components/common/forms/formik/TaxCodeSelector';
import TextField from 'components/common/forms/formik/TextField';
import { useUserInfoContext } from 'context/user-info.context';
import { getCurrencyByCode } from 'data/currencies';
import Decimal from 'decimal.js';
import { type ClientResponseDto, InvoiceFor } from 'dtos';
import { Form } from 'formik';
import useClientPaymentMethods from 'hooks/useClientPaymentMethods';
import useTaxCodes from 'hooks/useTaxCodes';
import moment from 'moment-timezone';
import type { TaxCodeDto } from 'pages/api/tax-codes';
import { useEffect, useState } from 'react';
import { useTypedFormikContext } from 'utils/client/formik';
import { getClientInvoiceFormEventHandlers } from './clientInvoiceEventHandlers';
import { type ClientInvoiceFormValues, FieldNames } from './schema';

enum ClientInvoiceTab {
  BASIC = 0,
  SCHEDULE = 1,
}

type ClientInvoiceFormProps = {
  disabled: boolean;
  handleAddNewClientSuccess: (client: ClientResponseDto) => void;
};

export default function ClientInvoiceForm({
  disabled,
  handleAddNewClientSuccess,
}: ClientInvoiceFormProps) {
  const userInfo = useUserInfoContext();
  const [tab] = useState(ClientInvoiceTab.BASIC);
  const [selectedTaxCode, setSelectedTaxCode] = useState<TaxCodeDto>();

  const { values, setFieldValue, errors } =
    useTypedFormikContext<ClientInvoiceFormValues>();

  const {
    id: clientInvoiceId,
    recipient: recipientValue,
    paid: paidFieldValue,
    paymentDate: paymentDateFieldValue,
    invoiceFor: invoiceForFieldValue,
    currencyCode: currencyCodeValue,
    amount: amountFieldValue,
    taxCodeId: taxCodeIdValue,
    isArc,
    clientPaymentMethodId,
  } = values;

  const taxEffectiveDate =
    paymentDateFieldValue ?? moment().startOf('hour').toDate();

  const { taxCodes } = useTaxCodes({
    effectiveDate: taxEffectiveDate,
  });

  const { data: paymentMethods, isLoading: arePaymentMethodsLoading } =
    useClientPaymentMethods({
      clientId: recipientValue?.id,
      clientInvoiceId,
    });

  useEffect(() => {
    const clearClientSpecificPaymentMethod = () => {
      if (
        clientPaymentMethodId &&
        !arePaymentMethodsLoading &&
        !paymentMethods?.find((x) => x.id === clientPaymentMethodId)
      ) {
        setFieldValue(FieldNames.CLIENT_PAYMENT_METHOD, null);
        setFieldValue(FieldNames.CLIENT_PAYMENT_METHOD_ID, null);
      }
    };
    clearClientSpecificPaymentMethod();
  }, [paymentMethods, clientPaymentMethodId]);

  useEffect(() => {
    if (taxCodeIdValue) {
      setSelectedTaxCode(taxCodes.find(({ id }) => id === taxCodeIdValue));
    }
  }, [taxCodes, taxCodeIdValue]);

  const selectedCurrency = getCurrencyByCode(currencyCodeValue);

  const selectedPaymentMethod = paymentMethods?.find(
    (pm) => pm.id === values[FieldNames.CLIENT_PAYMENT_METHOD_ID],
  );

  const handlers = getClientInvoiceFormEventHandlers({
    values,
    setFieldValue,
    paymentMethods: paymentMethods,
  });

  return (
    <Form>
      <TabPanel value={tab} index={ClientInvoiceTab.BASIC} hideWithCss>
        <Stack spacing={2}>
          <TextField
            name={FieldNames.SUBJECT}
            label="Subject"
            required
            fullWidth
            disabled={disabled}
          />
          <Stack direction="row" spacing={2}>
            <ClientSelector
              name={FieldNames.RECIPIENT}
              label="Client"
              required
              allowAdd
              disabled={disabled}
              onAddSuccess={(client) => handleAddNewClientSuccess(client)}
              includeCorporateGroups
            />
            <Select
              name={FieldNames.INVOICE_FOR}
              label={'Invoice for'}
              required
              disabled={disabled}
              options={[
                { value: InvoiceFor.FEES, label: 'Fees' },
                {
                  value: InvoiceFor.TRIP,
                  label: 'Trip Costs',
                },
              ]}
            />
          </Stack>

          {invoiceForFieldValue === InvoiceFor.FEES ? (
            <FeeTypeSelector
              name={FieldNames.FEE_TYPE_ID}
              label={'Type of Fee'}
              required
              disabled={disabled}
            />
          ) : undefined}

          <Stack direction="row" spacing={2}>
            {isArc && (
              <MuiTextField
                label="Payment Method"
                value="ARC"
                fullWidth
                disabled
                required
              />
            )}
            {!isArc && (
              <ClientPaymentPaymentMethodSelector
                name={FieldNames.CLIENT_PAYMENT_METHOD_ID}
                label={'Payment Method'}
                required
                disabled={disabled}
                onChange={(event) => {
                  const value = event.target.value as string;
                  handlers.handleClientPaymentMethodChange(value);
                }}
                clientId={recipientValue?.id}
                clientInvoiceId={clientInvoiceId}
              />
            )}

            <FormControl fullWidth>
              <CurrencySelector
                useManagedCurrencies
                name={FieldNames.CURRENCY_CODE}
                label="Currency"
                disabled={disabled}
              />
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={2}>
            <MoneyTextField
              name={FieldNames.AMOUNT}
              label="Total Charged to Client"
              required
              fullWidth
              disabled={disabled}
              symbol={selectedCurrency?.symbol}
              currencyCode={selectedCurrency?.code}
              onChange={(newValue) => {
                handlers.handleProcessingCostsChange({
                  field: 'total',
                  value: newValue,
                });
              }}
            />
            <MoneyTextField
              name={FieldNames.NET_TOTAL}
              label="Net Received"
              required
              fullWidth
              symbol={selectedCurrency?.symbol}
              currencyCode={selectedCurrency?.code}
              disabled={disabled}
              onChange={(newValue) => {
                handlers.handleProcessingCostsChange({
                  field: 'netTotal',
                  value: newValue,
                });
              }}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <PercentTextField
              name={FieldNames.PROCESSING_COST_PCT}
              label="Processing Rate"
              required
              fullWidth
              disabled={disabled}
              onChange={(newValue) => {
                handlers.handleProcessingCostsChange({
                  field: 'costPct',
                  value: newValue,
                });
              }}
            />
            <MoneyTextField
              name={FieldNames.PROCESSING_COST}
              label="Processing Costs"
              required
              fullWidth
              disabled={disabled}
              symbol={selectedCurrency?.symbol}
              currencyCode={selectedCurrency?.code}
              onChange={(newValue) => {
                handlers.handleProcessingCostsChange({
                  field: 'costAmount',
                  value: newValue,
                });
              }}
            />
          </Stack>

          {invoiceForFieldValue === InvoiceFor.FEES &&
            userInfo?.organization.useManagedTaxes && (
              <Stack direction="row" spacing={2}>
                <TaxCodeSelector
                  name={FieldNames.TAX_CODE_ID}
                  label={'Tax Code'}
                  required
                  disabled={disabled}
                  effectiveDate={taxEffectiveDate}
                />
                <MuiTextField
                  label="Tax"
                  value={new Decimal(amountFieldValue ?? 0)
                    .times(selectedTaxCode?.ratePercent ?? 0)
                    .dividedBy(100)
                    .toDecimalPlaces(2)
                    .toString()}
                  disabled
                  fullWidth
                  InputProps={{
                    inputComponent: MoneyFormat as never,
                    startAdornment: (
                      <InputAdornment position="start">
                        {selectedCurrency?.symbol}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {selectedCurrency?.code}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            )}

          {isArc && (
            <TextField
              name={FieldNames.DOCUMENT_NUMBER}
              label="Document Number"
              disabled={disabled || !userInfo?.isOrgUser}
              required
            />
          )}

          <FormControlLabel
            control={<Checkbox name={FieldNames.PAID} disabled={disabled} />}
            label="Paid"
            // if tripsuite cc processing is selected, they cannot mark as paid
            disabled={disabled || selectedPaymentMethod?.supportsDirectPayment}
          />

          <DatePicker
            name={
              paidFieldValue ? FieldNames.PAYMENT_DATE : FieldNames.DUE_DATE
            }
            label={paidFieldValue ? 'Payment Date' : 'Due Date'}
            disabled={disabled}
            required
          />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={ClientInvoiceTab.SCHEDULE} hideWithCss>
        <FormControl>
          <FormLabel>Payment method</FormLabel>
        </FormControl>
      </TabPanel>
    </Form>
  );
}
