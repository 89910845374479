import {
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from '@mui/material';
import T from 'components/common/T';
import { type SplitResponseDto, SplitType } from 'dtos';
import { getInitials } from 'utils/client/contextTree';
import ClientInvoiceCommissionSplitsAdvisorAvatar from './ClientInvoiceCommissionSplitsAdvisorAvatar';
import ClientInvoiceCommissionSplitsAgencyAvatar from './ClientInvoiceCommissionSplitsAgencyAvatar';

type ClientInvoiceCommissionSplitsListItemProps = {
  split: SplitResponseDto;
  index: number;
  onClick: (split: SplitResponseDto) => void;
  showPrimaryAdvisorChip?: boolean;
};

const ClientInvoiceCommissionSplitsListItem = ({
  index,
  onClick,
  split,
  showPrimaryAdvisorChip = false,
}: ClientInvoiceCommissionSplitsListItemProps) => (
  <ListItemButton
    dense
    onClick={() => onClick(split)}
    key={split.id}
    disabled={!split.canEdit}
  >
    <Box pl={index * 3} />
    <ListItemIcon>
      {split.type === SplitType.AGENCY ? (
        <ClientInvoiceCommissionSplitsAgencyAvatar
          color={split.color}
          initials={getInitials(split.name || '- -')}
        />
      ) : (
        <ClientInvoiceCommissionSplitsAdvisorAvatar
          color={split.color || '#000'}
          initials={getInitials(split.name)}
        />
      )}
    </ListItemIcon>

    <ListItemText primary={split.name} />

    <ListItemSecondaryAction>
      <Stack direction="row" spacing={2} alignItems="center">
        {showPrimaryAdvisorChip && split.type === SplitType.ADVISOR && (
          <Chip size="small" variant="outlined" label="Primary advisor" />
        )}
        <T variant="body2">{split.takePercent}%</T>
      </Stack>
    </ListItemSecondaryAction>
  </ListItemButton>
);

export default ClientInvoiceCommissionSplitsListItem;
