import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountingSyncIndicators from 'components/common/AccountingSyncIndicators';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';
import UserInfoContext from 'context/user-info.context';
import type {
  ClientInvoiceResponseDto,
  GroupedSplitsResponseDto,
  SplitResponseDto,
} from 'dtos';
import { Formik } from 'formik';
import { useClientInvoice, useTrip } from 'hooks';
import useClientPaymentMethods from 'hooks/useClientPaymentMethods';
import { useConfirm } from 'material-ui-confirm';
import { useContext, useEffect, useState } from 'react';
import { voidClientInvoice } from 'requests/clientInvoices';
import ClientInvoiceCommissionSplits, {
  calculateTotalAssignedPct,
} from './ClientInvoiceCommissionSplits/ClientInvoiceCommissionSplits';
import ClientInvoiceDeleteDialog from './ClientInvoiceDeleteDialog';
import ClientInvoiceDialogTitle from './ClientInvoiceDialogTitle';
import ClientInvoiceForm from './ClientInvoiceForm';
import ClientInvoiceSplitsDialog from './ClientInvoiceSplitsDialog/ClientInvoiceSplitsDialog';
import * as handlers from './handlers';
import { getDefaultValues, getSchema } from './schema';

const emptySplit: GroupedSplitsResponseDto = {
  maxPercent: 100,
  primarySplits: [],
  secondarySplits: [],
};

type ClientInvoiceDialogProps = {
  tripId: string;
  mode: 'add' | 'edit';
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  clientInvoiceId?: string;
  onSuccess: (
    mode: 'add' | 'edit',
    clientInvoice: ClientInvoiceResponseDto,
  ) => void;
};

export default function ClientInvoiceDialog({
  tripId,
  open,
  setOpen,
  mode,
  clientInvoiceId,
  onSuccess = () => null,
}: ClientInvoiceDialogProps) {
  const theme = useTheme();
  const confirm = useConfirm();
  const userInfo = useContext(UserInfoContext);

  const [splitMode, setSplitMode] = useState<'add' | 'edit' | null>(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [splits, setSplits] = useState<GroupedSplitsResponseDto>(emptySplit);

  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { trip, isLoading: isTripLoading } = useTrip(tripId);
  const {
    clientInvoice,
    isLoading: isClientInvoiceLoading,
    refresh: refreshClientInvoice,
  } = useClientInvoice({ clientInvoiceId });
  const {
    data: clientPaymentMethods,
    isLoading: isClientPaymentMethodsLoading,
  } = useClientPaymentMethods({
    clientId:
      clientInvoice?.recipientCorporateGroup?.id ??
      clientInvoice?.recipientClient?.id,
    clientInvoiceId,
  });

  const isLoading =
    isTripLoading || isClientInvoiceLoading || isClientPaymentMethodsLoading;
  const isDisabled =
    !!(clientInvoice?.locked && !userInfo?.isOrgUser) ||
    !!clientInvoice?.voidedAt;

  const showDeleteDialog =
    isDeleteDialogOpen && !isClientInvoiceLoading && !!clientInvoice;

  const onClose = () => setOpen(false);

  const handleConfirmDeleteInvoice = async () => {
    setOpen(false);
    setIsDeleteDialogOpen(false);
    onSuccess(mode, clientInvoice);
  };

  const handleVoidInvoice = async () => {
    confirm({
      title: 'Void Client Payment',
      description:
        'This action will cancel the payment and zero out the associated charges. This action cannot be undone.',
      confirmationText: 'Void Payment',
      cancellationButtonProps: {
        variant: 'contained',
        color: 'inherit',
        disableElevation: true,
      },
      confirmationButtonProps: {
        variant: 'text',
        color: 'error',
      },
    }).then(async () => {
      await voidClientInvoice(clientInvoiceId as string);
      await refreshClientInvoice();
      onSuccess(mode, clientInvoice);
    });
  };

  const onSplitUpdate = (split: SplitResponseDto) => {
    const primarySplits = splits.primarySplits.map((primarySplit) =>
      primarySplit.agencyId === split.agencyId &&
      primarySplit.agencyUserId === split.agencyUserId
        ? { ...primarySplit, takePercent: split.takePercent }
        : primarySplit,
    );
    const secondarySplits = splits.secondarySplits.map((secondarySplits) =>
      secondarySplits.map((secondarySplit) =>
        secondarySplit.agencyId === split.agencyId &&
        secondarySplit.agencyUserId === split.agencyUserId
          ? { ...secondarySplit, takePercent: split.takePercent }
          : secondarySplit,
      ),
    );

    const newSplits = {
      ...splits,
      primarySplits,
      secondarySplits,
    };

    setSplits(newSplits);
  };

  const onSplitAdded = (addedSplits: SplitResponseDto[]) =>
    setSplits({
      ...splits,
      secondarySplits: [...splits.secondarySplits, addedSplits],
    });

  const onResetValues = () => setSplits(clientInvoice?.splits || emptySplit);

  const onSplitRemove = (split: SplitResponseDto) => {
    // Assuming we can't remove an advisor from the primary split
    const secondarySplits = splits.secondarySplits.filter(
      (secondarySplits) =>
        !secondarySplits.some(
          (secondarySplit) =>
            secondarySplit.agencyUserId === split.agencyUserId,
        ),
    );

    const newSplits = {
      ...splits,
      secondarySplits,
    };

    setSplits(newSplits);
  };

  useEffect(() => {
    if (!clientInvoice?.splits) return;

    setSplits(clientInvoice?.splits);
  }, [clientInvoice]);

  const agencyUserIdsWithSplits = Array.from(
    new Set([
      ...(clientInvoice?.splits?.primarySplits.map(
        (split) => split.agencyUserId,
      ) ?? []),
      ...(clientInvoice?.splits?.secondarySplits.flatMap((secondarySplits) =>
        secondarySplits.map((split) => split.agencyUserId),
      ) ?? []),
    ]),
  );

  const showSplits = Boolean(
    userInfo?.isOrgUser &&
      !userInfo?.isOrgAssistant &&
      mode === 'edit' &&
      !clientInvoice?.voidedAt,
  );

  const hasSplitsError =
    splits &&
    mode === 'edit' &&
    calculateTotalAssignedPct(splits) !== splits.maxPercent;

  return (
    <Formik
      validationSchema={getSchema({
        useManagedTaxes: userInfo?.organization.useManagedTaxes ?? false,
      })}
      initialValues={getDefaultValues(
        clientInvoice,
        trip?.primaryClient,
        clientPaymentMethods,
        userInfo?.homeCurrency?.code,
      )}
      onSubmit={(values, formikHelpers) =>
        handlers.handleSubmit({
          values,
          formikHelpers,
          mode,
          tripId,
          clientInvoiceId,
          splits,
          onSuccess: () => {
            onSuccess(mode, clientInvoice);
            setOpen(false);
          },
          shouldUpdateSplits: showSplits,
        })
      }
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, validateForm }) => {
        const onAddSuccess = handlers.handleAddNewClientSuccess(setFieldValue);

        return (
          <>
            <Dialog
              fullScreen={isFullScreen}
              fullWidth
              maxWidth="md"
              open={open}
              aria-labelledby="client-invoice-dialog"
              sx={{
                '& .MuiDialog-paper': {
                  minHeight: 'min(760px, calc(100vh - 60px))',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress sx={{ m: 2 }} />
              ) : (
                <>
                  <AccountingSyncIndicators
                    isLegacyPayment={clientInvoice?.isLegacyPayment}
                    isOrgUser={userInfo?.isOrgUser}
                  />

                  <ClientInvoiceDialogTitle
                    mode={mode}
                    onDeleteClick={() => setIsDeleteDialogOpen(true)}
                    onVoidClick={handleVoidInvoice}
                    {...(clientInvoice ? { clientInvoice } : {})}
                    clientInvoiceTrip={trip}
                  />

                  <DialogContent
                    sx={(theme) => ({
                      pt: `${theme.spacing(1)}!important`,
                    })}
                  >
                    {userInfo && clientInvoice?.locked && (
                      <Box pb={2}>
                        {!userInfo.isOrgUser && (
                          <Alert severity="error">
                            <AlertTitle>Payment Locked</AlertTitle>
                            Editable by admin only
                          </Alert>
                        )}
                        {userInfo.isOrgUser && (
                          <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            Changes will impact accounting
                          </Alert>
                        )}
                      </Box>
                    )}
                    <ClientInvoiceForm
                      disabled={isDisabled}
                      handleAddNewClientSuccess={onAddSuccess}
                    />

                    {showSplits && (
                      <>
                        <Divider sx={{ py: 2 }} />
                        <Stack>
                          <ClientInvoiceCommissionSplits
                            onResetValues={onResetValues}
                            commissionSplits={splits}
                            onSplitUpdate={onSplitUpdate}
                            onSplitRemove={onSplitRemove}
                          />
                          <Button
                            variant="text"
                            onClick={() => setSplitMode('add')}
                            sx={{ mt: 2, mr: 'auto' }}
                            startIcon={<AddIcon />}
                          >
                            New Advisor Split
                          </Button>
                          {splitMode === 'add' && clientInvoiceId && (
                            <ClientInvoiceSplitsDialog
                              clientInvoiceId={clientInvoiceId}
                              open={splitMode === 'add'}
                              setOpen={(open) =>
                                setSplitMode(open ? 'add' : null)
                              }
                              mode={splitMode}
                              onSplitAdded={onSplitAdded}
                              excludeAgencyUserIds={agencyUserIdsWithSplits}
                            />
                          )}
                        </Stack>
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button disabled={isSubmitting} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting || !!trip?.lockedAt || isDisabled}
                      variant="contained"
                      onClick={() => {
                        if (hasSplitsError && showSplits) {
                          validateForm();
                          return;
                        }

                        clientInvoice?.locked
                          ? setConfirmationOpen(true)
                          : handleSubmit();
                      }}
                    >
                      {mode === 'add' ? 'Create' : 'Update'} Client Payment
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>

            {confirmationOpen ? (
              <ConfirmationDialog
                title="Confirm Changes"
                message="Are you sure you want to update this payment? Changes will impact accounting."
                confirmButtonMessage="I’m sure, update payment"
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={async () => {
                  setConfirmationOpen(false);
                  await handleSubmit();
                }}
                onCancel={() => {
                  setConfirmationOpen(false);
                  onClose();
                }}
              />
            ) : undefined}

            {showDeleteDialog ? (
              <ClientInvoiceDeleteDialog
                open={showDeleteDialog}
                clientInvoice={clientInvoice}
                onClose={() => {
                  setIsDeleteDialogOpen(false);
                }}
                onSuccess={handleConfirmDeleteInvoice}
              />
            ) : undefined}
          </>
        );
      }}
    </Formik>
  );
}
