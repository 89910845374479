import {
  type ClientResponseDto,
  ErrorResponseDto,
  type GroupedSplitsResponseDto,
} from 'dtos';
import type { FormikHelpers } from 'formik';
import { enqueueSnackbar as toast } from 'notistack';
import {
  createClientInvoice,
  updateClientInvoice,
} from 'requests/clientInvoices';
import {
  type ClientInvoiceFormValues,
  getClientInvoiceFromFormValues,
} from './schema';

type HandleSubmitProps = {
  values: ClientInvoiceFormValues;
  formikHelpers: FormikHelpers<ClientInvoiceFormValues>;
  mode: 'add' | 'edit';
  clientInvoiceId?: string;
  tripId: string;
  splits: GroupedSplitsResponseDto;
  onSuccess: () => void;
  shouldUpdateSplits: boolean;
};

const handleSubmit = async ({
  values,
  formikHelpers: { setSubmitting },
  mode,
  clientInvoiceId,
  tripId,
  splits,
  onSuccess,
  shouldUpdateSplits = false,
}: HandleSubmitProps) => {
  try {
    const parsedData = getClientInvoiceFromFormValues(values);
    if (shouldUpdateSplits) {
      parsedData.splits = [
        ...splits.primarySplits,
        ...splits.secondarySplits.flat(),
      ];
    }

    mode === 'edit' && clientInvoiceId
      ? await updateClientInvoice(parsedData, clientInvoiceId)
      : await createClientInvoice({ ...parsedData, tripId: tripId });

    toast(
      `Client Payment ${mode === 'add' ? 'created' : 'updated'} successfully`,
    );

    onSuccess();
  } catch (e) {
    console.error(e);
  } finally {
    setSubmitting(false);
  }
};

const handleAddNewClientSuccess =
  (setFieldValue: FormikHelpers<ClientInvoiceFormValues>['setFieldValue']) =>
  (client: ClientResponseDto) => {
    void setFieldValue('recipient', {
      id: client.id,
      name: client.name,
    });
  };

export { handleAddNewClientSuccess, handleSubmit };
