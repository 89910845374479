import type { ListItem } from 'components/types';
import { USD } from 'data/currencies';
import {
  type ClientInvoiceResponseDto,
  type ClientInvoiceUpdateRequestDto,
  type ClientPaymentMethodResponseDto,
  InvoiceFor,
  type PaymentMethod,
  type TripResponseDto,
} from 'dtos';
import type { FormikValues } from 'formik';
import moment from 'moment-timezone';
import { createYupSchema } from 'utils/client/types';
import * as yup from 'yup';

export enum FieldNames {
  ID = 'id',
  SUBJECT = 'subject',
  RECIPIENT = 'recipient',
  INVOICE_FOR = 'invoiceFor',
  FEE_TYPE_ID = 'feeTypeId',
  CLIENT_PAYMENT_METHOD = 'clientPaymentMethod',
  CLIENT_PAYMENT_METHOD_ID = 'clientPaymentMethodId',
  AMOUNT = 'amount',
  PAID = 'paid',
  PAYMENT_DATE = 'paymentDate',
  DUE_DATE = 'dueDate',
  PROCESSING_COST = 'processingCost',
  PROCESSING_COST_PCT = 'processingCostPct',
  NET_TOTAL = 'netTotal',
  CURRENCY_CODE = 'currencyCode',
  DOCUMENT_NUMBER = 'documentNumber',
  IS_ARC = 'isArc',
  TAX_CODE_ID = 'taxCodeId',
}

export interface ClientInvoiceFormValues extends FormikValues {
  [FieldNames.ID]: string | undefined;
  [FieldNames.SUBJECT]: string;
  [FieldNames.RECIPIENT]: ListItem | null;
  [FieldNames.INVOICE_FOR]: InvoiceFor | null;
  [FieldNames.FEE_TYPE_ID]: string | null;
  [FieldNames.CLIENT_PAYMENT_METHOD]: PaymentMethod | null;
  [FieldNames.CLIENT_PAYMENT_METHOD_ID]: string | null;
  [FieldNames.AMOUNT]: number | null;
  [FieldNames.PAID]: boolean;
  [FieldNames.PAYMENT_DATE]: Date | null;
  [FieldNames.DUE_DATE]: Date | null;
  [FieldNames.PROCESSING_COST]: number | null;
  [FieldNames.PROCESSING_COST_PCT]?: number;
  [FieldNames.NET_TOTAL]: number | null;
  [FieldNames.CURRENCY_CODE]: string;
  [FieldNames.DOCUMENT_NUMBER]: string | undefined;
  [FieldNames.IS_ARC]: boolean;
  [FieldNames.TAX_CODE_ID]: string | null;
}

const getSchema = ({ useManagedTaxes }: { useManagedTaxes: boolean }) => {
  const clientPaymentMethodSchema = yup
    .string()
    .label('Payment Method')
    .typeError('Payment Method is a required field');

  const yupSchema: Record<
    Exclude<FieldNames, FieldNames.CLIENT_PAYMENT_METHOD>,
    yup.AnySchema
  > = {
    id: yup.string().optional(),
    subject: yup.string().required().min(3).label('Subject'),
    recipient: yup
      .object()
      .nullable()
      .required()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .label('Recipient'),
    invoiceFor: yup
      .mixed<InvoiceFor>()
      .oneOf(Object.values(InvoiceFor))
      .required()
      .label('Invoice For'),
    feeTypeId: yup
      .string()
      .label('Type of Fee')
      .when('invoiceFor', {
        is: InvoiceFor.FEES,
        then: (schema) =>
          process.env.NEXT_PUBLIC_TYPE_OF_FEE_FIELD_ENABLED === '1'
            ? schema.required()
            : schema.nullable(),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError('Type of Fee is a required field'),
    clientPaymentMethodId: yup.string().when('isArc', {
      is: true,
      then: (schema) => clientPaymentMethodSchema.nullable(),
      otherwise: (schema) => clientPaymentMethodSchema.required(),
    }),
    amount: yup
      .number()
      .min(0, 'Fee Amount must be greater than or equal to 0')
      .required()
      .label('Fees Amount')
      .typeError('Total is a required field'),
    processingCost: yup
      .number()
      .required()
      .min(0)
      .max(
        yup.ref(FieldNames.AMOUNT),
        'Processing Cost cannot exceed Total charged to client',
      )
      .label('Processing Cost')
      .typeError('Please enter a valid processing cost amount'),
    processingCostPct: yup
      .number()
      .required()
      .min(0)
      .max(100)
      .label('Processing Cost %')
      .typeError('Please enter a number between 0 and 100'),
    netTotal: yup
      .number()
      .min(0, 'Net Total must be greater than or equal to 0')
      .required()
      .label('Net Total')
      .typeError('Net Total is a required field'),
    paid: yup.boolean(),
    paymentDate: yup
      .date()
      .nullable()
      .typeError('A valid date is required')
      .label('Payment Date')
      .when('paid', { is: true, then: (schema) => schema.required() }),
    dueDate: yup
      .date()
      .nullable()
      .typeError('A valid date is required')
      .label('Due Date')
      .when('paid', { is: false, then: (schema) => schema.required() }),
    currencyCode: yup.string().required().label('Currency'),
    isArc: yup.boolean(),
    documentNumber: yup
      .string()
      .label('Document Number')
      .when('isArc', { is: true, then: (schema) => schema.required() }),
    taxCodeId: yup
      .string()
      .label('Tax Code')
      .when('invoiceFor', {
        is: InvoiceFor.FEES,
        then: (schema) =>
          useManagedTaxes ? schema.required() : schema.nullable(),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError('Tax Code is a required field'),
  };

  const schema =
    createYupSchema<
      Omit<ClientInvoiceFormValues, FieldNames.CLIENT_PAYMENT_METHOD>
    >(yupSchema);
  return schema;
};

const getInvoiceRecipient = (
  clientInvoice?: ClientInvoiceResponseDto,
  primaryClient?: TripResponseDto['primaryClient'],
) => {
  if (!clientInvoice && !primaryClient) return null;

  return clientInvoice?.recipientCorporateGroup
    ? {
        id: clientInvoice.recipientCorporateGroup.id,
        name: clientInvoice.recipientCorporateGroup.name,
      }
    : clientInvoice?.recipientClient
      ? {
          id: clientInvoice.recipientClient.id,
          name: clientInvoice.recipientClient.name,
        }
      : primaryClient
        ? { id: primaryClient.id, name: primaryClient.name }
        : null;
};

const getDefaultValues = (
  clientInvoice: ClientInvoiceResponseDto | undefined,
  primaryClient?: TripResponseDto['primaryClient'],
  clientPaymentMethods?: ClientPaymentMethodResponseDto[],
  defaultCurrencyCode = USD,
): ClientInvoiceFormValues => {
  const recipient = getInvoiceRecipient(clientInvoice, primaryClient);

  const clientPaymentMethodProcessingCostPct =
    clientPaymentMethods?.find(
      (method) => method.id === clientInvoice?.clientPaymentMethodId,
    )?.processingCostPct ?? 0;

  const processingCost =
    (clientInvoice?.processingCostPct !== undefined ||
      clientPaymentMethodProcessingCostPct !== undefined) &&
    clientInvoice?.amount
      ? ((clientInvoice.processingCostPct ??
          clientPaymentMethodProcessingCostPct ??
          0) *
          clientInvoice.amount) /
        100
      : null;

  const netTotal = (clientInvoice?.amount || 0) - (processingCost || 0);

  return {
    id: clientInvoice?.id ?? undefined,
    subject: clientInvoice?.subject || '',
    recipient,
    invoiceFor: clientInvoice?.invoiceFor || null,
    clientPaymentMethod: clientInvoice?.paymentMethod || null,
    clientPaymentMethodId:
      clientPaymentMethods?.find(
        (method) => method.id === clientInvoice?.clientPaymentMethodId,
      )?.id || null,
    amount: clientInvoice?.amount || null,
    paid: !!clientInvoice?.paidAt,
    dueDate: clientInvoice?.dueDate || null,
    paymentDate: clientInvoice?.paidAt ? new Date(clientInvoice.paidAt) : null,
    feeTypeId: clientInvoice?.feeType?.id || null,
    processingCost: processingCost,
    processingCostPct:
      clientInvoice?.processingCostPct ?? clientPaymentMethodProcessingCostPct,
    netTotal: netTotal || null,
    currencyCode: clientInvoice?.currency ?? defaultCurrencyCode,
    isArc: clientInvoice?.isArc || false,
    documentNumber: clientInvoice?.documentNumber,
    taxCodeId: clientInvoice?.taxCodeId ?? null,
  };
};

const getClientInvoiceFromFormValues = (
  values: ClientInvoiceFormValues,
): ClientInvoiceUpdateRequestDto => ({
  subject: values[FieldNames.SUBJECT] || '',
  recipientId: values[FieldNames.RECIPIENT]?.id || '',
  invoiceFor: values[FieldNames.INVOICE_FOR] as InvoiceFor,
  paymentMethod: values[FieldNames.CLIENT_PAYMENT_METHOD] as PaymentMethod,
  clientPaymentMethodId:
    values[FieldNames.CLIENT_PAYMENT_METHOD_ID] ?? undefined,
  amount: Number(values[FieldNames.AMOUNT]) || 0,
  paidAt: values[FieldNames.PAID]
    ? moment(values[FieldNames.PAYMENT_DATE]).toISOString()
    : undefined,
  dueDate: values[FieldNames.PAID]
    ? undefined
    : moment(values[FieldNames.DUE_DATE]).toISOString(),
  currency: values[FieldNames.CURRENCY_CODE],
  feeTypeId: values[FieldNames.FEE_TYPE_ID] || undefined,
  processingCostPct: values[FieldNames.PROCESSING_COST_PCT] || 0,
  documentNumber: values[FieldNames.DOCUMENT_NUMBER],
  taxCodeId: values[FieldNames.TAX_CODE_ID] ?? undefined,
});

export { getClientInvoiceFromFormValues, getDefaultValues, getSchema };
