import { RestartAlt } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemText,
  type SxProps,
} from '@mui/material';

type ClientInvoiceCommissionSplitsHeaderProps = {
  onResetValues: () => void;
  sx?: SxProps;
};

export default function ClientInvoiceCommissionSplitsHeader({
  onResetValues,
  sx,
}: ClientInvoiceCommissionSplitsHeaderProps) {
  return (
    <ListItem
      disableGutters
      secondaryAction={
        <IconButton onClick={onResetValues}>
          <RestartAlt />
        </IconButton>
      }
      sx={sx}
    >
      <ListItemText primary="Commission Splits" secondary="Advisor sourced" />
    </ListItem>
  );
}
