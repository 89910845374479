import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useField } from 'formik';
import useTaxCodes from 'hooks/useTaxCodes';

type TaxCodeSelectorProps<T extends string = string> = {
  name: T;
  label: string;
  required?: boolean;
  disabled?: boolean;
  effectiveDate: Date;
};

export default function TaxCodeSelector<T extends string = string>({
  name,
  label,
  required,
  disabled,
  effectiveDate,
}: TaxCodeSelectorProps<T>) {
  const [field, meta, helpers] = useField(name);

  const { taxCodes } = useTaxCodes({ effectiveDate });

  return (
    <FormControl
      fullWidth
      error={!!meta.error}
      disabled={disabled}
      required={required}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select {...field} labelId={`${name}-label`} label={label}>
        {taxCodes.map((taxCode) => (
          <MenuItem key={taxCode.id} value={taxCode.id}>
            {taxCode.name} ({taxCode.ratePercent}%)
          </MenuItem>
        ))}
      </Select>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
