import type {
  ClientInvoiceCreateRequestDto,
  ClientInvoiceResponseDto,
  ClientInvoiceUpdateRequestDto,
  ClientPaymentMethodResponseDto,
  TripSplitsResponseValueDto,
} from 'dtos';
import ky from 'utils/client/ky';

export const createClientInvoice = (
  clientInvoice: ClientInvoiceCreateRequestDto,
) =>
  ky
    .post('/api/client-invoices', {
      json: clientInvoice,
    })
    .json();

export const updateClientInvoice = (
  clientInvoice: ClientInvoiceUpdateRequestDto,
  id: string,
) =>
  ky
    .put(`/api/client-invoices/${id}`, {
      json: clientInvoice,
    })
    .json();

export const deleteClientInvoice = (invoiceId: string) =>
  ky.delete(`/api/client-invoices/${invoiceId}`).json();

export const getClientPaymentPaymentMethods = () =>
  ky
    .get('/api/client-payment-methods')
    .json<ClientPaymentMethodResponseDto[]>();

export const processClientInvoice = ({
  clientInvoiceId,
  clientProfileCreditCardId,
}: {
  clientInvoiceId: string;
  clientProfileCreditCardId: string;
}) =>
  ky
    .post(`/api/client-invoices/${clientInvoiceId}/process-payment`, {
      json: {
        clientProfileCreditCardId,
      },
    })
    .json();

export const getClientInvoiceDefaultSplits = ({
  clientInvoiceId,
  agencyUserId,
}: { clientInvoiceId: string; agencyUserId: string }) =>
  ky
    .get(`/api/client-invoices/${clientInvoiceId}/default-splits`, {
      searchParams: { agencyUserId },
    })
    .json<TripSplitsResponseValueDto>()
    .then(({ splits }) => splits);

export const voidClientInvoice = (id: string) =>
  ky.post(`/api/client-invoices/${id}/void`).json<ClientInvoiceResponseDto>();
