import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import GoToTripChip from 'components/bookings/GoToTripChip';
import T from 'components/common/T';
import Tooltip from 'components/common/Tooltip';
import type { ClientInvoiceResponseDto, TripResponseDto } from 'dtos';
import { useState } from 'react';
import { niceDate, niceShortDate } from 'utils/client/formatting';

type ClientInvoiceDialogTitleProps = {
  mode: 'add' | 'edit';
  onDeleteClick: () => void;
  onVoidClick: () => void;
  clientInvoice?: ClientInvoiceResponseDto;
  clientInvoiceTrip: TripResponseDto;
};

const ClientInvoiceDialogTitle = ({
  mode,
  onDeleteClick,
  onVoidClick,
  clientInvoice,
  clientInvoiceTrip,
}: ClientInvoiceDialogTitleProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  return (
    <DialogTitle p={'0!important'}>
      {clientInvoice?.voidedAt && (
        <Alert
          severity="error"
          sx={{
            backgroundColor: (theme) => theme.palette.black.dark,
            color: (theme) => theme.palette.common.white,
            '& .MuiAlert-icon': {
              color: (theme) => theme.palette.common.white,
            },
          }}
        >
          Voided {niceDate(clientInvoice.voidedAt)}
        </Alert>
      )}
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
        px={3}
        pt={2}
        mb={1}
      >
        <Stack>
          <T variant="h6">{`${
            mode === 'add' ? 'New' : 'Edit'
          } Client Payment`}</T>
          {mode === 'edit' && clientInvoice && (
            <T variant="subtitle2" color="text.secondary">
              {`Created ${niceShortDate(clientInvoice.createdAt)}`}
            </T>
          )}
        </Stack>
        {mode === 'edit' && (
          <Stack direction="row" alignItems="center" spacing={1}>
            {clientInvoiceTrip && (
              <GoToTripChip
                tripName={clientInvoiceTrip?.name}
                tripId={clientInvoiceTrip?.id}
              />
            )}
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem
                onClick={() => {
                  onDeleteClick();
                  setAnchorEl(null);
                }}
              >
                Delete Client Payment
              </MenuItem>
              <Tooltip title={clientInvoice?.unvoidableReasons ?? []}>
                <MenuItem
                  disabled={!clientInvoice?.isVoidable}
                  onClick={() => {
                    onVoidClick();
                    setAnchorEl(null);
                  }}
                >
                  Void Client Payment
                </MenuItem>
              </Tooltip>
            </Menu>
          </Stack>
        )}
      </Stack>
    </DialogTitle>
  );
};

export default ClientInvoiceDialogTitle;
